/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.css';

import Glide, { Autoplay, Controls, Breakpoints, Swipe, Keyboard, Images } from '@glidejs/glide/dist/glide.modular.esm'
import $ from 'jquery';
import "magnific-popup";
import Sortable from 'sortablejs';

$( document ).ready(function() {
    let testGlide = document.getElementsByClassName('glide');

    if (testGlide.length > 0) {
        let glide = new Glide('.glide', {
            autoplay: 3000,
            type: 'carousel',
            gap: 0,
            hoverpause: true,
            focus: 'center',
            perView: 1
        }).mount({ Autoplay, Controls, Breakpoints, Swipe, Keyboard, Images });
        glide.play();
    }

    let popupDiv = $('#div-popup');

    if ($(popupDiv).data('link') !== undefined) {
        console.log($(popupDiv).data('link'));
        let popup = $.magnificPopup.open({
            items: {
                src: $(popupDiv).data('link')
            },
            type: 'image'

            // You may add options here, they're exactly the same as for $.fn.magnificPopup call
            // Note that some settings that rely on click event (like disableOn or midClick) will not work here
        }, 0);
    }

    let galerieNonActiveSlides = document.getElementById('galerie-non-active-slides');
    let galerieActiveSlides = document.getElementById('galerie-active-slides');

    let ficheGammesEscalierList = document.getElementById('fiches_gammes_escalier_list');
    let fichesGardesCorpsList = document.getElementById('fiches_gardes_corps_list');

    let sortableListOptions = {
        animation: 150,
        ghostClass: 'blue-background-class',
        onChange: function(/**Event*/evt) {
            evt.from.children.forEach(function (element, index) {
                let idInput = document.getElementsByName('fiches_order['+element.dataset.id+'][id]')[0];
                let orderInput = document.getElementsByName('fiches_order['+element.dataset.id+'][order]')[0];

                idInput.value = element.dataset.id;
                orderInput.value = index;
            });
        }
    };

    if (ficheGammesEscalierList) {
        new Sortable(ficheGammesEscalierList, sortableListOptions);
    }

    if (fichesGardesCorpsList) {
        new Sortable(fichesGardesCorpsList, sortableListOptions);
    }


    let a = null;
    let b = null;

    if (galerieNonActiveSlides && galerieActiveSlides) {
        let sortableOptions = {
            group: 'shared', // set both lists to same group
            animation: 150,
            dataIdAttr: 'data-id',

            // Called when dragging element changes position
            onChange: function(/**Event*/evt) {
                initGalerie();
                evt.newIndex // most likely why this event is used is to get the dragging element's current index
                // same properties as onEnd
                let tmp = galerieNonActiveSlides === evt.to;
                let tmp2 = galerieActiveSlides === evt.to;

                var itemEl = evt.item;  // dragged HTMLElement
                evt.to;    // target list
                evt.from;  // previous list
                evt.oldIndex;  // element's old index within old parent
                evt.newIndex;  // element's new index within new parent
                evt.oldDraggableIndex; // element's old index within old parent, only counting draggable elements
                evt.newDraggableIndex; // element's new index within new parent, only counting draggable elements
                evt.clone // the clone element
                evt.pullMode;  // when item is in another sortable: `"clone"` if cloning, `true` if moving
            }
        };

        a = new Sortable(galerieNonActiveSlides, sortableOptions);
        b = new Sortable(galerieActiveSlides, sortableOptions);

        initGalerie();
    }

    function initGalerie() {
        a.el.children.forEach(function (element, index) {
            initFormGalerie(element, index);
        });
        b.el.children.forEach(function (element, index) {
            initFormGalerie(element, index, true);
        });
    }

    function initFormGalerie(element, index, active = false) {
        let idInput = document.getElementsByName('galerie_pictures_order['+element.dataset.id+'][id]')[0];
        let orderInput = document.getElementsByName('galerie_pictures_order['+element.dataset.id+'][order]')[0];
        let activeInput = document.getElementsByName('galerie_pictures_order['+element.dataset.id+'][active]')[0];

        idInput.value = element.dataset.id;
        orderInput.value = index;

        activeInput.checked = active;
    }

});

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery';
